
import { Options, Vue } from 'vue-class-component';
import { AppConfigClient, UserClient } from '@/services/Services';
import store from '@/store';
import * as VM from '@/viewModel';

@Options({})
export default class Categories extends Vue {

    categories: VM.SelectableCategoryConfig[] = [];
    
    created() {
        AppConfigClient.getCategories()
        .then(x => {
            this.categories = x;
            this.categories.forEach(x => {
                x.selected = store.state.user.categories.some(c => c == x.name);
            })
        });
    }

    next() {
        store.state.user.categories = this.categories.filter(x => x.selected).map(x => x.name);
        store.state.globalSpinner++;
        UserClient.updateAppUser(store.state.user)
        .then(x => {
            if(this.$route.params.hasBackButton != 'false') {
                this.$router.back();
            } else {
                if(this.$route.query.redirect){
                    this.$router.replace(this.$route.query.redirect as string);
                } else {
                    this.$router.replace('/home');
                }
            }
        })
        .finally(() => {
            store.state.globalSpinner--;
        })
    }

}

